import React, { useMemo, useState, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import SourceRoutes from "./SourceRoutes";
import keycloak from "../lib/keycloak";
import NotFound from "pages/404";
import { ROLES_KEY } from "constants";
import MainLayout from "layouts/MainLayout";
import Agents from "pages/Admin/Agents";
import CreditSummary from "pages/Admin/CreditSummary/CreditSummary";
import CreditSummaryList from "pages/Admin/CreditSummary/CreditSummaryList";
import CreditsHistoryList from "pages/Admin/CreditSummary/CreditsHistoryList";
import UserManagement from "pages/Admin/Usermangement/Usermanagment";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { ProgressSpinner } from "primereact/progressspinner";
import UserProfile from "components/DocumentEngine/UserManagement/UserProfile";
import NotAccessible from "./NotAccessible";
import { useSharedState } from "context/ContextWrapper";
import LoginScreen from "pages/Login/Login";
import Authentication from "hooks/authentication";
import { isSettlementDemand } from "constants";
import Spaces from "pages/Admin/Spaces/SpacesList";
import { Toast } from "primereact/toast";
import {
  StreamDataSources,
  StreamDataSourcesDetails,
  StreamDetailRuleList,
} from "pages/Admin/Pulse";
import {
  StructuredSources,
  DocumentSources,
  NewSource,
  EditSource,
} from "pages/Admin/SourceConfig";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import CustomerInsights from "pages/Admin/Spaces/CustomerInsights";
import NewSpaceConnectors from "components/Spaces/NewSpaceConnectors";
import { getSession, setKeycloakSession } from "utils/utils";
import GetUsersList from "hooks/getUsers";
const RouterContent = () => {
  const applicationAccess = ["documentengine", "space"];
  const toast = useRef(null);
  const { keycloak, initialized } = useKeycloak();
  const { authenticated } = keycloak;
  const userInfo = keycloak?.tokenParsed;
  const { sharedState, setSharedState } = useSharedState();
  const navigate = useNavigate();
  const location = useLocation();
  const [isVerifying, setIsVerifying] = useState(true);
  const { emailVerification, updateUserStatus, getOrganizations } = Authentication();
  const { getUserfromMicrosoft } = GetUsersList();
  const [tokenRefreshInterval, setTokenRefreshInterval] = useState(null);

  useEffect(() => {
    const session = getSession();
    if (session) {
      const currentTime = Math.floor(Date.now() / 1000);
      if (session.tokenParsed?.exp && session.tokenParsed.exp > currentTime) {
        setKeycloakSession(session);
      } else {
        sessionStorage.removeItem('keycloakSession');
      }
    }
  }, []);

  useEffect(() => {
    const isInDocumentEngine = location.pathname.includes("/intelliapp");
    if (
      (applicationAccess?.includes("documentengine") && isInDocumentEngine) ||
      isSettlementDemand
    ) {
      document.title = "Settlement Demand Ai";
      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        favicon.href = "/settlement-demand-favicon.png";
      } else {
        const newFavicon = document.createElement("link");
        newFavicon.rel = "icon";
        newFavicon.href = "/settlement-demand-favicon.png";
        document.head.appendChild(newFavicon);
      }
    } else {
      document.title = "NeIO AI";
      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        favicon.href = "/favicon.ico";
      }
    }
  }, [applicationAccess, location.pathname]);
  useEffect(() => {
    // console.log('sharedState:', sharedState);
  }, [sharedState]);

  // const roles = useMemo(() => {
  //   if (authenticated && initialized) {
  //     const role = keycloak.tokenParsed?.resource_access?.[ROLES_KEY]?.roles;
  //     const roleString = role?.includes("admin") ? "admin" : "user";
  //     setSharedState(roleString);
  //     return role;
  //   }
  //   return [];
  // }, [authenticated, initialized, keycloak.tokenParsed?.roles]);

  const fetchOrganizations = async (domain) => {
    try {
      const response = await getOrganizations(domain);
      if (response?.status === 200) {
        return response?.results !== false ? response?.results?.idp_alias : false;
      }
    } catch (error) {
      console.error("Failed to fetch organizations:", error);
    }
  };

  const getUserList = async () => {
    try {
      const domain = userInfo?.email?.split("@")[1];
      if (!domain) {
        if (toast?.current) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unable to get user domain"
          });
        }
        return;
      }

      const idpAlias = await fetchOrganizations(domain);
      if (idpAlias) {
        try {
          await getUserfromMicrosoft(idpAlias);
        } catch (e) {
          if (toast?.current) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unable to fetch users"
            });
          }
        }
      } else {
        if (toast?.current) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "User is not a part of any organization"
          });
        }
      }
    } catch (err) {
      if (toast?.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An unexpected error occurred"
        });
      }
    }
  };

  const roles = useMemo(() => {
    if (authenticated && initialized) {
      const role = keycloak.tokenParsed?.resource_access?.[ROLES_KEY]?.roles;
      const roleString = role?.includes("admin") ? "admin" : "user";
      setSharedState(roleString);
      return role;
    }
    return [];
  }, [authenticated, initialized, keycloak.tokenParsed?.roles]);

  useEffect(() => {
    if (roles.length > 0) {
      const roleString = roles.includes("admin") ? "admin" : "user";
      setSharedState(roleString);
    }
  }, [roles, setSharedState]);

  useEffect(() => {
    const verifyEmail = async () => {
      if (authenticated && initialized && keycloak.tokenParsed?.email) {
        try {
          const response = await emailVerification(keycloak.tokenParsed.email);
          if (response?.status === "pending") {
            await updateUserStatus({
              email: keycloak.tokenParsed.email,
              keycloak_id: keycloak.tokenParsed.user_id,
            });
          }
        } catch (error) {
          console.error("Email verification failed:", error);
        } finally {
          setTimeout(() => {
            setIsVerifying(false);
          }, 3000);
        }
      } else {
        setTimeout(() => {
          setIsVerifying(false);
        }, 3000);
      }
    };

    verifyEmail();
  }, [authenticated, initialized]);

  useEffect(() => {
    if (authenticated && initialized) {
      const refreshToken = () => {
        const tokenExpiresIn = keycloak.tokenParsed.exp - Math.ceil(Date.now() / 1000);
        if (tokenExpiresIn < 60) {
          keycloak.updateToken(60).then(() => {
            // Token refresh successful
          }).catch((error) => {
            console.error('Token refresh failed:', error);
            keycloak.logout().then(() => {
              sessionStorage.removeItem('keycloakSession');
              navigate("/login", { replace: true });
            });
          });
        }
      };
      getUserList();
      const interval = setInterval(refreshToken, 30000);
      setTokenRefreshInterval(interval);

      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [authenticated, initialized, navigate]);

  if (isVerifying) {
    return (
      <div className="flex flex-column justify-content-center h-screen align-items-center">
        <ProgressSpinner />
        <h3 className="mt-3">Verifying your account...</h3>
      </div>
    );
  }

  if (!authenticated && location.pathname !== "/signup" && location.pathname !== "/login") {
    location.pathname === "/signup" ? navigate("/signup") : navigate("/login");
    return null;
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginScreen currentState="login" />} />
      <Route path="/signup" element={<LoginScreen currentState="signup" />} />
      {sharedState === "admin" && (
        <>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Agents />} />
            <Route path="agent" element={<Agents />} />
            <Route path="credits" element={<CreditSummary />} />
            <Route path="credits-list" element={<CreditSummaryList />} />
            <Route path="credits-org-history" element={<CreditsHistoryList />} />
            <Route path="usersmanagement" element={<UserManagement />} />
            <Route path="user/:id" element={<UserProfile />} />
            <Route path="spaces/file-data" element={<Spaces />} />
            <Route path="spaces/stream-data" element={<Spaces />} />
            <Route path="spaces/:spaceId" element={<CustomerInsights />} />
            <Route path="spaces/space-list" element={<Spaces />} />
            <Route path="pulse/stream-source" element={<StreamDataSources />} />
            <Route path="pulse/stream-source/:name" element={<StreamDataSourcesDetails />} />
            <Route
              path="pulse/stream-source/:name/stream-detail-rules"
              element={<StreamDetailRuleList />}
            />
            <Route path="source-configuration/source" element={<StructuredSources />} />
            <Route path="source-configuration/document-source" element={<DocumentSources />} />
            <Route path="source-configuration/source/new" element={<NewSource />} />
            <Route path="source-configuration/source/edit/:id" element={<EditSource />} />
            <Route path="spaces/file-data/:id/new-space-setup" element={<NewSpaceConnectors />} />
          </Route>
          <Route
            path="/intelliapp/*"
            element={<SourceRoutes applicationAccess={applicationAccess} accessLevel={"admin"} />}
          />
        </>
      )}
      {sharedState === "user" && (
        <Route
          path="/intelliapp/*"
          element={
            <SourceRoutes
              applicationAccess={applicationAccess}
              authenticated={authenticated}
              accessLevel={"user"}
            />
          }
        />
      )}
      {initialized && authenticated && sharedState !== "admin" && sharedState !== "user" && (
        <Route path="*" element={<NotAccessible />} />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default function Router() {
  const handleKeyCloakEvent = (event, error) => {
    if (event === "onTokenExpired") {
      keycloak
        .updateToken(60)
        .then((refreshed) => {
          if (refreshed) {
            console.log("Token refreshed successfully");
          }
        })
        .catch(() => {
          window.location.href = "/login";
        });
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "check-sso",
        checkLoginIframe: false,
        enableLogging: true,
        pkceMethod: false,
        tokenMinValidity: 60,
      }}
      onEvent={handleKeyCloakEvent}
    >
      <BrowserRouter>
        <RouterContent />
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
}
