import React, { useRef, useMemo, useEffect, useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { Avatar } from "primereact/avatar";
import { Toast } from "primereact/toast";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../Header/header.css";
import bellIcon from "../../assets/Header/BellIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import agents from "../../assets/Header/agents.svg";
import pulse from "../../assets/Pulse/pulse.svg";
import settings from "../../assets/SettingLogo/Settings.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import useMediaQuerry from "hooks/useMediaQuerry";
import { useKeycloak } from "@react-keycloak/web";
import { ROLES_KEY } from "constants";
import templates from "../../assets/Header/templates.svg";
import workspaces from "../../assets/Header/workspaces.svg";
import credits from "../../assets/Header/credits.svg";
import sources from '../../assets/Header/sources.svg';
import users from "../../assets/Header/users.svg";
import { getInitials, getRolesFromOrganization, getSession } from "../../utils/utils";
import { useSharedState } from "context/ContextWrapper";
import coins from "../../assets/Header/coins.svg";
import CreditSummarySidebar from "components/CreditSummarySidebar/CreditSummarySidbar";
import GetUserCredits from "hooks/getUserCredits";
import settlementDemand from "../../assets/IntelliappLogo/settlementdemand.png";
import { useUserStatusProvider } from "context/userStatusContext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { connect, StringCodec } from "nats.ws";
import Notifications from "components/Notifications/Notifications";

const HeaderComponent = ({ menuItems, selectedItem, view }) => {
  const { keycloak } = useKeycloak();
  const { authenticated } = keycloak;
  const navigate = useNavigate();
  const location = useLocation();
  const op = useRef(null);
  const { isMobile } = useMediaQuerry();
  const userInfo = useMemo(() => keycloak?.tokenParsed, []);
  const { sharedState, setSharedState } = useSharedState();
  const toast = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const session = getSession();

  const isAdmin = keycloak.tokenParsed?.resource_access?.[ROLES_KEY]?.roles?.includes("admin");

  const getIconByName = (name) => {
    switch (name.toLowerCase()) {
      case "agent":
        return agents;
      case "usermgt.":
        return users;
      case "credits":
        return credits;
      case "templates":
        return templates;
      case "workspaces":
        return workspaces;
      case "users":
        return users;
      case "settings":
        return settings;
      case "source":
        return sources;
      case "space":
        return sources;
      case "pulse":
        return pulse;
      default:
        console.warn(`No icon found for: ${name}`);
        return null;
    }
  };
  const icon = getIconByName(selectedItem);

  const getDisplayName = (name) => {
    switch (name.toLowerCase()) {
      case "agent":
        return "Agents";
      case "usermgt.":
        return "User Management";
      case "credits":
        return "Credits";
      case "templates":
        return "Templates";
      case "workspaces":
        return "Workspaces";
      case "users":
        return "User Management";
      case "settings":
        return "Settings";
      case "space":
        return "Space";
      case "source":
        return "Source Configuration";
      case "pulse":
        const pathSegments = location.pathname.split("/").filter(Boolean);
        const breadcrumbItems = [];

        // Add Pulse as first item
        breadcrumbItems.push(
          <span
            key="pulse"
            className="cursor-pointer text-black"
            onClick={() => navigate("/pulse/stream-source")}
          >
            Pulse
          </span>,
        );

        // Add Stream Data Sources if in that route
        if (pathSegments.includes("stream-source")) {
          breadcrumbItems.push(
            <span key="separator1" className="mx-2">
              /
            </span>,
          );
          breadcrumbItems.push(
            <span
              key="stream-sources"
              className={`${pathSegments.length === 2 ? "text-gray-400" : "cursor-pointer text-black"}`}
              onClick={() => pathSegments.length > 2 && navigate("/pulse/stream-source")}
            >
              Stream Data Sources
            </span>,
          );
        }

        // Add source name if present
        if (location?.state?.source?.name) {
          breadcrumbItems.push(
            <span key="separator2" className="mx-2">
              /
            </span>,
          );
          breadcrumbItems.push(
            <span key="source-name" className="text-gray-400">
              {location.state.source.name}
            </span>,
          );
        } else if(location?.pathname?.endsWith('stream-detail-rules')){
          breadcrumbItems.push(
            <span key="separator2" className="mx-2">
              /
            </span>,
          );
          breadcrumbItems.push(
            <span key="source-name" 
            // className="text-gray-400"
            className={`${pathSegments.length === 3 ? "text-gray-400" : "cursor-pointer text-black"}`}
              onClick={() => pathSegments.length > 3 && window.history.back()}
            >
              {decodeURIComponent(location?.pathname?.split("/")[3])}
            </span>,
          );
        }

        // Add stream ID if present
        if (location?.state?.sourceRowData?.stream_id) {
          breadcrumbItems.push(
            <span key="separator3" className="mx-2">
              /
            </span>,
          );
          breadcrumbItems.push(
            <span key="stream-id" className="text-gray-400">
              {location.state.sourceRowData.stream_id}
            </span>,
          );
        }

        return <div className="flex items-center">{breadcrumbItems}</div>;
      default:
        return name;
    }
  };
  const displayName = getDisplayName(selectedItem);

  useEffect(() => {
    if (sharedState === "admin") {
      toast?.current?.show({
        severity: "info",
        detail: sharedState === "admin" ? "You are now Admin" : "You are now User",
        duration: 3000,
      });
    }
  }, [sharedState]);

  const handleSwitch = () => {
    setSharedState((prevState) => (prevState === "user" ? "admin" : "user"));
  };

  const handleLogout = () => {
    if (session) {
      sessionStorage.removeItem('keycloakSession');
      keycloak.authenticated = false;
      keycloak.logout();
    } else {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "You have been logged out successfully.",
        life: 2000,
      });
      setTimeout(() => {
        keycloak.logout({
          id_token_hint: keycloak.idToken,
          post_logout_redirect_uri: window.location.origin,
        });
      }, 1000);

    }
  };
  const leftContents =
    view === "intelliapp" && isAdmin && sharedState === "admin" ? (
      <div className="flex gap-2 cursor-pointer" onClick={() => navigate("/")}>
        <i className="pi pi-angle-left align-content-center"></i>
        <p className="bg-black font-bold text-sm">Back to Agents</p>
      </div>
    ) : view === "intelliapp" && sharedState === "user" ? (
      <div className="flex text-sm gap-2 items-center justify-center">
        <img src={settlementDemand} alt="Settlement Demand" />
      </div>
    ) : (
      <div className="flex text-sm gap-2 items-center justify-center">
        {icon && (
          <div className="flex items-center align-content-center">
            {displayName === "User Management" ? (
              <i className="pi pi-users flex" style={{ alignItems: "center" }} />
            ) : (
              <img src={getIconByName(selectedItem)} alt={selectedItem} className="" />
            )}
          </div>
        )}
        <div className="bg-black font-bold text-sm my-auto title-container">
          {getDisplayName(selectedItem)}
        </div>
      </div>
    );

  const centerContent = view === "intelliapp" && (
    <p className="text-sm hidden md:block header-center my-0">
      This is beta release - upload documents up to 350 pages maximum for the best results, and
      review all AI-generated outputs for accuracy
    </p>
  );

  const rightContents = (
    <>
      <Toast ref={toast} />
      {/* {!isAdmin && (
        <Button
          style={{ backgroundColor: "#FFF8F3", borderColor: "#FEDDC7" }}
          className="border-none text-gray-900 font-xs text-sm border-2"
          label={
            <span className="flex align-items-center">
              <img
                src={coins}
                alt="Credits"
                className="mr-2"
                style={{ width: "20px", height: "20px" }}
              />
              {`${creditData.credit_balance} credits`}
            </span>
          }
          onClick={() => setIsVisible(true)}
        />
      )}
      <CreditSummarySidebar visible={visible} setIsVisible={setIsVisible} creditData={creditData} /> */}

      <Notifications userInfo={userInfo} authenticated={authenticated} />

      <div className="flex align-items-center gap-2">
        <Avatar
          icon="pi pi-user"
          className="p-mr-2 bg-blue-100"
          shape="circle"
          onClick={(e) => op.current.toggle(e)}
        />
        <OverlayPanel
          ref={op}
          id="overlay_panel"
          className={`surface-0 ${!isMobile ? "w-[90vw] max-w-[350px] right-0" : ""}  sm:right-auto`}
        >
          <div className="p-2 border-b border-gray-200">
            <div className="flex items-center">
              <Avatar
                label={getInitials(userInfo?.name)}
                className="bg-blue-100 text-blue-600 mr-3 text-sm"
                shape="circle"
                size="large"
              />
              <div>
                <div className="text-sm font-semibold mb-1">{userInfo?.name}</div>
                <p className="text-xs text-gray-600 m-0">{userInfo?.email}</p>
              </div>
            </div>
          </div>
          <hr className="hr-popup-border	 border-gray-200 my-2 -mx-3" />
          <div className=" pl-2">
            {isAdmin && (
              <>
                <p className="text-xs text-gray-500 mb-2">
                  CURRENT VIEW: {sharedState === "admin" ? "Admin" : "User"}
                </p>
                <Button
                  label={sharedState === "admin" ? "View as User" : "View as Admin"}
                  className="bg-white logout-popup-button p-0 py-2 text-gray-600 text-xs w-full justify-center mb-2"
                  onClick={() => handleSwitch()}
                />
                <hr className="hr-popup-border border-gray-200  my-2 -mx-4" />
              </>
            )}
            <Button
              label="Logout"
              className="bg-white logout-popup-button p-0 py-2 text-gray-600 text-xs w-full justify-center"
              onClick={() => setShowDialog(true)}
            />
          </div>
        </OverlayPanel>
      </div>
    </>
  );

  return (
    <>
      <ConfirmDialog
        group="declarative"
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        message="Are you sure you want to log out?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={handleLogout}
        acceptIcon={"pi pi-check"}
        rejectIcon={"pi pi-times"}
        reject={() => {
          setShowDialog(false);
        }}
        acceptLabel="Logout"
        rejectLabel="Cancel"
        acceptClassName="p-accept-button"
        rejectClassName="p-reject-button"
        style={{ width: "50vw" }}
        breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
      />
      <Toolbar
        left={leftContents}
        center={centerContent}
        right={rightContents}
        className="header"
      />
      {view === "intelliapp" && (
        <div className="mob-beta block md:hidden mb-5 px-4">
          <p className="text-sm header-center my-0">
            This is beta release - upload documents up to 350 pages maximum for the best results,
            and review all AI-generated outputs for accuracy
          </p>
        </div>
      )}
    </>
  );
};

export default HeaderComponent;
