import React from "react";
import BookLogo from "assets/BookIcons/BookLogo";
import WorkspaceIcon from "assets/WorkSpaceIcons/WorkspaceIcon";
import UserManagementLogo from "assets/UserManagement/UserManagement";
import CreditManagementIcon from "assets/CreditOverview/CreditManagementIcon";
import SpaceIcon from "assets/SpaceIcons/SpaceIcon";
import SettingsIcon from "assets/SettingLogo/SettingsIcon";
import PulseIcon from "assets/Sidebar/PulseIcon";
import AgentIcon from "assets/Sidebar/Agent";
import ChatIcon from "assets/Sidebar/ChatIcon";
import DashboardIcon from "assets/SDAI/Dashboard/DashboardIcon";
export const IntelliAppNavigationMap = {
  dashboard: `/intelliapp/dashboard`,
  templates: `/intelliapp/templates`,
  workspaces: `/intelliapp/workspaces`,
  ["users"]: `/intelliapp/settings/usersmanagement`,
  ["credits"]: "/intelliapp/settings/credits",
};

export const DashboardNavigationMap = {
  workspaces: `/dashboard`,
  ["credits"]: `/credits`,
  agent: `/agent`,
  ["users"]: `/usersmanagement`,
  ["source"]: `source-configuration/source`,
  ["pulse"]: `pulse/stream-source`,
  space: `/spaces/file-data`,
  fileData: `/spaces/file-data`,
  streamData: `/spaces/stream-data`,
  spaceList: `/spaces/space-list`,
  streamDataSources: `/pulse/stream-source`,
  sourceConfigurationStructured: `/source-configuration/source`,
  sourceConfigurationDocument: `/source-configuration/document-source`,
  sourceConfigurationNewStructured: `/source-configuration/source/new`,
  sourceConfigurationEditStructured: `/source-configuration/source/edit`,
};

export const SettingsMenuItems = [
  {
    icon: (selectedItem) => <UserManagementLogo selectedItem={selectedItem} />,
    label: "Users",
    path: "/usersmanagement",
  },
  {
    icon: (selectedItem) => <CreditManagementIcon selectedItem={selectedItem} />,
    label: "Credits",
    path: "/credits",
  },
];

export const SettingsIntelliAppNavigationMap = [
  {
    icon: (selectedItem) => <UserManagementLogo selectedItem={selectedItem} />,
    label: "Users",
    path: "/intelliapp/settings/usersmanagement",
  },
  {
    icon: (selectedItem) => <CreditManagementIcon selectedItem={selectedItem} />,
    label: "Credits",
    path: "/intelliapp/settings/credits",
  },
];

export const IntelliAppmenuItems = [
  {
    label: "Dashboard",
    icon: (selectedItem) => <DashboardIcon selectedItem={selectedItem} />,
  },
  {
    label: "Workspaces",
    icon: (selectedItem) => <WorkspaceIcon selectedItem={selectedItem} />,
  },
  {
    label: "Templates",
    icon: (selectedItem) => <BookLogo selectedItem={selectedItem} />,
  },
  {
    label: "Settings",
    icon: (selectedItem) => <SettingsIcon selectedItem={selectedItem} />,
  },
];

export const DashboardmenuItems = [
  {
    label: "Space",
    icon: (selectedItem) => <SpaceIcon selectedItem={selectedItem} />,
  },
  {
    label: "Chat",
    icon: (selectedItem) => <ChatIcon selectedItem={selectedItem} />,
  },
  {
    label: "Agent",
    icon: (selectedItem) => <AgentIcon selectedItem={selectedItem} />,
  },
  {
    label: "Source",
    icon: (selectedItem) => <BookLogo selectedItem={selectedItem} />,
  },
  {
    label: "Pulse",
    icon: (selectedItem) => <PulseIcon selectedItem={selectedItem} />,
  },
  {
    label: "Settings",
    icon: (selectedItem) => <SettingsIcon selectedItem={selectedItem} />,
  },
];

export const SpaceMenuItems = [
  {
    label: "Data System",
    icon: (selectedItem) => <BookLogo selectedItem={selectedItem} />,
  },
  {
    label: "File Data",
    icon: (selectedItem) => <BookLogo selectedItem={selectedItem} />,
  },
  {
    label: "Stream Data",
    icon: (selectedItem) => <BookLogo selectedItem={selectedItem} />,
  },
];

export const PulseMenuItems = [
  {
    label: "Stream Data Sources",
    icon: (selectedItem) => <BookLogo selectedItem={selectedItem} />,
  },
];

export const paths = {
  sourceConfig: {
    structured: () => {
      return `source-configuration/source`;
    },
    document: () => {
      return `source-configuration/document-source`;
    },
    newStructured: () => {
      return `source-configuration/source/new`;
    },
    editStructured: () => {
      return `source-configuration/source/edit`;
    },
  },
  pulse: {
    streamSource: () => {
      return `pulse/stream-source`;
    },
  },
  connectors: () => {
    return `/connectors`;
  },
  space: {
    spaceList: () => {
      return `spaces/space-list`;
    },
    fileData: () => {
      return `spaces/file-data`;
    },
    streamData: () => {
      return `spaces/stream-data`;
    },
  },
};
